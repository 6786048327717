import React from "react";
import { Link } from "gatsby";
import SEO from "../components/seo";
import H1 from "../components/h1";

const containerStyle = {
	display: "grid",
	gridTemplateColumns: `repeat(auto-fit, minmax(320px, 1fr))`,
	gridGap: "2rem",
};

function ContactPage() {
	return (
		<div>
			<SEO title="Contact" keywords={[]} />
			<H1 text="Contact" />
			<div style={containerStyle}>
				<div>
					<p className="mb-4">
						Please do not hesitate to contact our friendly and helpful staff for any inquiries, booking or support you
						might require.
					</p>
					<div className="mb-4">
						<p>
							Gastroenterologist
							<br />
							Hollywood Specialist Centre
							<br />
							Suite 10/11
							<br />
							95 Monash Ave.
							<br />
							Nedlands WA 6009
						</p>
					</div>
					<div className="mb-4 text-primary">
						<p>
							<a
								href="tel:0893891733"
								className="no-underline text-primary hover:text-primary-dark hover:font-semibold"
							>
								Phone: 08 9389 1733
							</a>
							<br />
							Fax: 08 9389 1755
							<br />
							Healthlink: advancgi
							<br />
							<a
								href="mailto:info@advancedgiwa.com.au"
								className="text-primary hover:text-primary-dark hover:font-semibold"
							>
								info@advancedgiwa.com.au
							</a>
							<br />
							<Link className="no-underline text-primary hover:text-primary-dark hover:font-semibold" to="/">
								www.advancedgiwa.com.au
							</Link>
						</p>
					</div>
				</div>
				<div className="w-full min-h-full">
					<iframe
						title="Google Maps"
						src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13538.678461638969!2d115.8106528!3d-31.9698586!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb111c814df4e0a5e!2sAdvanced+GI+Services!5e0!3m2!1sen!2sau!4v1552441884072"
						allowFullScreen
						className="w-full h-full"
					/>
				</div>
				<div className="mb-4">
					<p>
						Office Hours:
						<br />
						Monday - Thursday 8am - 5pm
						<br />
						Friday - 8am - 3pm
						<br />
						Saturday/Sunday - Closed
						<br />
						<br />
						{/* We are presently closed for the Christamas break and will reopen on the 9th January 2024 */}

					</p>
				</div>
			</div>
		</div>
	);
}

export default ContactPage;

// eslint-disable-next-line no-lone-blocks
{
	/* <form className="mx-auto md:w-1/2">
				<p className="leading-loose mb-8">
					Here is an example of a form built using Tailwind. Click{" "}
					<a href="https://tailwindcss.com/docs/examples/forms" className="font-bold no-underline text-grey-darkest">
						here
					</a>{" "}
					to see more examples.
				</p>

				<label className="block font-bold mb-2 text-xs uppercase" htmlFor="first-name">
					First Name
				</label>

				<input
					className="appearance-none block bg-grey-lighter mb-6 p-3 rounded-md text-grey-darker w-full"
					id="first-name"
					type="text"
					placeholder="Bill"
				/>

				<label className="block font-bold mb-2 text-xs uppercase" htmlFor="last-name">
					Last Name
				</label>

				<input
					className="appearance-none block bg-grey-lighter mb-6 p-3 rounded-md text-grey-darker w-full"
					id="last-name"
					type="text"
					placeholder="Murray"
				/>

				<label className="block font-bold mb-2 text-xs uppercase" htmlFor="message">
					Message
				</label>

				<textarea
					className="appearance-none bg-grey-lighter mb-6 p-3 rounded-md text-grey-darker w-full"
					placeholder="Say something..."
					rows="8"
				/>

				<button className="border-b-4 border-grey-darker hover:border-grey-dark bg-grey-dark hover:bg-grey font-bold px-6 py-3 rounded text-sm text-white">
					Submit
				</button>
			</form> */
}
